<template>
    <div v-if="$store.state.isAdvanceModalOpen" class="modal fade show" aria-labelledby="addNewCardTitle" style="display: block; background: #1c1d1c33">
        <div class="modal-dialog modal-dialog-centered" ref="modalRef">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ 'Select Advance Receipt' }}</h1>
                            <button
                                @click="onClickClose"
                                type="button"
                                class="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                </div>

                <hr class="mt-0 mb-0">

                <div class="pb-2">

                    <div class="col-11 py-1 mx-auto">
                        <table class="table border">
                            <thead>
                                <tr>
                                   <th>
                                       Receipt Number
                                   </th>
                                    <th>Available Amount</th>
                                    <th>Adjust Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(receipt, i) in receipts" :key="i">
                                  <td class="cursor-pointer"
                                      @change="toggleReceipt(receipt)"
                                  >
                                    <input
                                        type="checkbox"
                                        :value="receipt.id"
                                        v-model="selectedReceipts"
                                    />
                                    {{ receipt.voucher_no }}
                                  </td>
                                    <td>
                                        {{ receipt.available_amount }}
                                    </td>
                                    <td>
                                      <input
                                          name="amount"
                                          type="number"
                                          v-model="receipt.advance_amount"
                                          class="form-control width-50-per"
                                          id="advance-amount"
                                          :disabled="!selectedReceipts.includes(receipt.id)"
                                          @input="validateAmount(receipt)"
                                      />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="mt-2">
                            <p> <span class="text-bold">Bill Amount:</span> {{total}} </p>
                            <div class="form-group row">
                                <label
                                    class="col-sm-4 col-form-label text-body text-bold"
                                    for="advance-amount"
                                    style="font-size: 1rem"
                                >
                                    Advance Amount:
                                </label>
                                <input
                                    name="amount"
                                    type="number"
                                    v-model="advanceAmount"
                                    class="form-control width-50-per"
                                    id="advance-amount"
                                />
                                <transition name="fade">
                                    <span
                                        v-if="formError.advanceAmount"
                                        class="invalid-feedback d-block qcont"
                                    >
                                        {{ formError.advanceAmount }}
                                    </span>
                                </transition>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 text-center">
                        <SubmitCancelBtn @on-submit="submit" @on-cancel="onClickClose"  :loading="loading"/>
                    </div>

                </div>
            </div>
        </div>
        <Loader v-if="loader"/>
    </div>
</template>

<script setup>
import Loader from "@/components/atom/LoaderComponent";
import {inject, onMounted, ref, watch} from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import SubmitCancelBtn from "@/components/atom/SubmitCancelBtn.vue";

const props = defineProps({
    receipts: {
        type: Array,
        default: []
    },
    total: {
        type: Number
    }
});
const emit = defineEmits(['onCreateProfile']);
const $store = useStore();
const $route = useRoute();
const modalRef = ref(null);
const formData = inject("formData");
const receiptId = ref(null);
const advanceAmount = ref(0);
const loading = ref(false);
const loader = ref(false);
const formError = ref({
    advanceAmount: ""
});
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const selectedReceipts = ref([]);
const lastModifiedReceipt = ref(null);

const submit = () => {
  formData.value.advance_receipts = selectedReceipts.value.map(id => {
    const receipt = props.receipts.find(r => r.id === id);
    return { advance_receipt_id:id, advance_amount: receipt.advance_amount || 0 };
  });
  formData.value.advance_amount = advanceAmount.value;
  onClickClose();
}

const toggleReceipt = (receipt) => {
  if (selectedReceipts.value.includes(receipt.id)) {
     receipt.advance_amount = 0;
  }
  if (!selectedReceipts.value.includes(receipt.id)) {
     receipt.advance_amount = null;
  }
  updateTotal();
};

const updateTotal = () => {
  advanceAmount.value = props.receipts.reduce((sum, receipt) => {
    if (selectedReceipts.value.includes(receipt.id)) {
      return sum + (receipt.advance_amount || 0);
    }
    return sum;
  }, 0);

  if (advanceAmount.value > props.total && lastModifiedReceipt.value) {
      lastModifiedReceipt.value.advance_amount = 0;
      updateTotal();
  }
};

const validateAmount = (receipt) => {
  if (receipt.advance_amount > receipt.available_amount) {
    showError(`Adjust amount cannot exceed Available Amount:${receipt.available_amount}`);
    receipt.advance_amount = receipt.available_amount;
  }
  if (receipt.advance_amount < 0) {
    showError("Amount cannot be less then 0");
    receipt.advance_amount = 0;
  }

  lastModifiedReceipt.value = receipt;
  updateTotal();
};

const onClickClose = () => {
    $store.state.isAdvanceModalOpen = false;
}

//watchers
watch(advanceAmount, (newValue) => {
    formError.value.advanceAmount = '';

    if (!newValue) {
        formError.value.advanceAmount = "Advance amount cannot be 0";
    }

    if (newValue > props.total) {
        formError.value.advanceAmount = `Advance amount cannot be bigger than total amount (${props.total})`;
    }
})

onMounted(() => {
    selectedReceipts.value = formData.value.advance_receipts ? formData.value.advance_receipts.map(r => r.advance_receipt_id) : [];
    advanceAmount.value = formData.value.advance_amount || 0;
    updateTotal();
})

</script>
